import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/app/error.module.scss");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/components/molecules/styles/img.module.scss");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/services/utils/icons/notfound.png");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/services/utils/placeholder/Beauty.png");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/services/utils/placeholder/Food.png");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/services/utils/placeholder/Healthcare.png");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/services/utils/placeholder/medicine.png");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/services/utils/placeholder/Pet & vet.png");
import(/* webpackMode: "eager" */ "/Users/bxzahid/Documents/Playground/arogga/website-mobile/src/services/utils/placeholder/Undefine_image.png")